@import "../../breakpoints.scss";

.banner-parent {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 70px);
  gap: 1rem;
  padding-top: 0;
  overflow: hidden;
  position: relative;

  & > div {
    flex: 1;
  }

  @include respond-to("small") {
    flex-direction: column;
    & > div {
      flex: none;
    }
  }
  @include respond-to("medium") {
    flex-direction: column;
    & > div {
      flex: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 100px;
    right: -15px;
    width: 100px;
    height: 100px;
    background-image: url("../../images/dots.png");
  }

  &::after {
    content: "";
    position: absolute;
    top: 70%;
    left: -8px;
    width: 100px;
    height: 100px;
    background-image: url("../../images/dots.png");
  }
}

.banner-caption {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @include respond-to("small") {
    max-width: 100%;
    align-items: center;
    z-index: 1;
  }
  @include respond-to("medium") {
    max-width: 100%;
    align-items: center;
    z-index: 1;
  }
}

.banner-caption-title {
  font-size: 4.5em;
  font-weight: 700;
  color: #3a3a3a;
  @include respond-to("small") {
    font-size: 4em;
  }
  @include respond-to("medium") {
    font-size: 4em;
  }
}
.banner-image {
  width: 100%;
  @include respond-to("medium") {
    opacity: 0.2;
  }
  @include respond-to("small") {
    opacity: 0.2;
  }
}

.banner-parent > div:nth-child(1) {
  z-index: 3;
}

@include respond-to("medium") {
  .banner-parent > div:nth-child(2) {
    position: absolute;
    z-index: 2;
  }
}
@include respond-to("small") {
  .banner-parent > div:nth-child(2) {
    position: absolute;
    z-index: 2;
  }
}
.banner-description {
  font-size: 1.3em;
  font-weight: bold;
  color: #1c2541;
  @include respond-to("small") {
    text-align: center;
  }
  @include respond-to("medium") {
    text-align: center;
  }
}
