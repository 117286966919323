.latest-arrivals {
  max-width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.latest-arrivals-title {
  font-size: 1.4em;
  padding-bottom: 1.5rem;
  color: #0b132b;
  font-weight: 500;
}
.slick-slide {
  max-height: 20rem;
}
.left-arrow,
.right-arrow {
  border: none;
  border-radius: 2rem;
  background-color: #fff;
  width: 3rem;
  height: 3rem;
  box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 15%);
  cursor: pointer;
  position: relative;
}
.arrows {
  display: flex;
  gap: 2rem;
  justify-content: center;
  position: relative;
  top: 2rem;
}
.slide {
  padding: 1.5rem;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  p {
    text-align: center;
    font-weight: bold;
  }
  img {
    max-height: 6rem;
  }
  &:hover {
    z-index: 2;
  }
}
.slick-slide {
  padding: 1rem;
  & > div {
    transition: box-shadow 0.25s ease-in-out;
    border-radius: 8px;
    &:hover {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
}
