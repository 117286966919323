@import "../../breakpoints.scss";

.brands > div {
  scroll-margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-block: 1rem;
  padding: 1.5rem 3rem 2.5rem 3rem;
  border-radius: 1rem;
  box-shadow: 1px 0px 7px 1px #ddd;
}
.brand-logos {
  display: flex;
  justify-content: space-around;
  @include respond-to("small") {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
  }
}
.brand-logos img {
  height: 5rem;
}
.brand-logos img:not(:first-child) {
  @include respond-to("small") {
    height: 6rem;
  }
}

.brand-title {
  font-size: 1.4em;
  padding-bottom: 1.5rem;
  text-align: center;
  color: #0b132b;
  font-weight: 500;
}
