@import "../../breakpoints.scss";

.footer {
  min-height: 250px;
  background-color: #0b132b;
  color: #c1d6dd;
  padding: 3rem;
  padding-bottom: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 2rem;
  position: relative;
  @include respond-to("medium") {
    padding: 2rem;
  }
  &::before {
    content: "";

    --s: 100px; /* control the size */

    --_g: #0000 90deg, #366 0;
    background: conic-gradient(from 90deg at 2px 2px, var(--_g)),
      conic-gradient(from 90deg at 1px 1px, var(--_g));
    background-size: var(--s) var(--s), calc(var(--s) / 5) calc(var(--s) / 5);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 0 0;
    opacity: 0.15;
  }
  .copyright-text {
    font-size: 0.85em;
    color: #8a8a8a;
    grid-column: 1 / 3;
    display: flex;
    justify-content: center;
    @include respond-to("medium") {
      font-size: 0.7em;
    }
    @include respond-to("small") {
      font-size: 0.7em;
    }
  }

  .social-icons-and-description {
    display: flex;
    flex-direction: column;
    & > span {
      font-weight: 700;
      font-size: 2em;
      color: #fff;
      @include respond-to("small") {
        font-size: 1.3em;
      }
      @include respond-to("medium") {
        font-size: 1.3em;
      }
    }
  }
  .icon {
    @include respond-to("small") {
      font-size: 0.3em;
    }
    @include respond-to("medium") {
      font-size: 0.3em;
    }
  }
  .title {
    font-size: 3em;
    padding-bottom: 1rem;
    padding-top: 2rem;
  }
  .footer-description {
    margin: 2rem;
  }
  .social-icons {
    z-index: 20;
    margin-top: 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;

    svg:active {
      opacity: 0.5;
    }
  }
  .language-picker {
    display: flex;
    justify-content: flex-end;
    .language-picker-dropdown {
      border: 1px solid #fff;
    }
  }
}
