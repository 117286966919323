@import "./breakpoints.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: "GTWalsheimPro-Medium", sans-serif;
}

.App {
  min-width: clamp(320px, 50vw, 1200px);
}
.section-margin {
  margin-block: 10rem;
  @include respond-to("small") {
    margin-block: 7rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.wrapper {
  padding-inline: 4rem;
  margin-inline: auto;
  @include respond-to("small") {
    padding-inline: 2rem;
  }
}

body[data-dir="rtl"] {
  .banner-caption,
  .about-category,
  .products-title,
  .latest-arrivals-title,
  #contact {
    text-align: right;
  }
}
