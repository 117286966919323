@import "../../breakpoints.scss";

.about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1.5rem;
  gap: 2.5rem;
  @include respond-to("small") {
    flex-direction: column;
    gap: 2rem;
  }
}

.about-title {
  font-size: 1.4em;
  padding-bottom: 2rem;
  color: #0b132b;
  font-weight: 500;
}

.about-category {
  padding: 2rem;
  width: 50%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0px 7px 1px #ddd;
  @include respond-to("small") {
    width: 100%;
  }
}
.about-description {
  line-height: 1.6;
  color: #3a506b;
}
