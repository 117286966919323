@import "../../breakpoints.scss";
#contact {
  line-height: 1.6;
  .contact-title,
  .location-title {
    font-size: 1.4em;
    padding-bottom: 1.5rem;
    color: #0b132b;
    font-weight: 500;
  }

  .contact-items {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    margin-block: 5rem;
    flex-wrap: wrap;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      }

      .contact-sub-title {
        font-size: 1.4em;
        padding-bottom: 1.5rem;
        color: #1a2139;
        font-weight: 500;
      }

      a {
        display: block;
        margin-bottom: 0.5rem;
      }
      a {
        color: #18272f;
        position: relative;
        text-decoration: none;
      }

      a::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        border-radius: 4px;
        background-color: #18272f;
        bottom: 0;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform 0.3s ease-in-out;
      }

      a:hover::before {
        transform-origin: left;
        transform: scaleX(1);
      }

      a:active {
        opacity: 0.5;
      }
    }
  }

  .location-title {
    margin-top: 12px;
  }

  .locations {
    display: grid;
    grid-template-columns: 256px 1fr 1fr;
    gap: 0.5rem;
    max-width: 960px;
    margin-inline: auto;

    svg {
      align-content: end;
      position: relative;
      justify-self: center;
      top: 8px;
    }

    @include respond-to("medium") {
      grid-template-columns: 150px 1fr 1fr;
    }

    @include respond-to("small") {
      grid-template-columns: 120px 1fr;
      row-gap: 1rem;

      svg {
        grid-row: span 2;
        justify-self: center;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;

      :nth-of-type(1) {
        font-weight: 600;
        font-size: 1.25rem;
      }
    }
  }
}
