@import "../../breakpoints.scss";

#products {
  scroll-margin-top: 2rem;

  .products {
    max-width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    position: relative;
    opacity: 0.7;
  }
  .products::before,
  .products::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 25%;
    z-index: 1;
  }
  .products::after {
    right: 0;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.9395981903503589) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .products::before {
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9395981903503589) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .products:first-child {
    margin-top: 4rem;
  }

  .products-title {
    font-size: 1.4em;
    padding-bottom: 1.5rem;
    color: #0b132b;
    font-weight: 500;
  }

  .logoMarqueeSection {
    overflow: hidden;
    padding-top: 2rem;
  }

  #logoMarqueeSection {
    max-width: 1920px !important;
    margin: 0 auto;
  }

  .default-content-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
    width: 100%;
    min-height: 100vh;
  }

  div.marquee > span > img {
    max-width: 119px;
    max-height: 80px;
    margin-right: 4rem;
    @include respond-to("small") {
      max-width: 90px;
      max-height: 60px;
    }
  }

  .logoMarqueeSection > div > div {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
  }

  .marquee-wrapper {
    display: inline-block;
    white-space: nowrap;
  }

  .marquee {
    display: inline-block;
    white-space: nowrap;
    position: relative;
    transform: translate3d(0%, 0, 0);
    animation-name: marquee;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 20s;
  }
  .marquee-row-2 {
    animation-duration: 35s;
  }
  .marquee-row-3 {
    animation-duration: 57s;
  }
  .marquee a {
    display: inline-block;
    white-space: nowrap;
    padding-right: 5.4rem;
  }

  @keyframes marquee {
    0% {
      transform: translate3d(0%, 0, 0);
    }

    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }
  .marquee a {
    width: auto;
    max-width: none;
  }
}
