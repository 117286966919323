@import "../../breakpoints.scss";

.navbar {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  transition: top 0.3s ease-in-out;
  flex-direction: row;
  padding-inline: 1.5rem;
  padding-block: 0.9rem;
  background-color: white;
  z-index: 10;
  position: sticky;
  top: 0;
  box-shadow: -1px -5px 20px 2px #0000004f;
  color: #0b132b;
  min-height: 70px;

  @include respond-to("medium") {
    z-index: 20;
  }
  @include respond-to("small") {
    z-index: 20;
  }
}

.logo {
  cursor: pointer;
}
.list-of-menu-items {
  list-style-type: none;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  @include respond-to("medium") {
    position: absolute;
    top: 70px;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    background-color: #fff;
    padding: 1.25rem 2rem;
  }

  li {
    width: 100%;
    display: block;
    a {
      display: block;
      width: 100%;
      white-space: nowrap;
      border-radius: 5px;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: #f0eeee;
      }
    }
  }
  &.visible {
    box-shadow: 0px 5px 5px 1px rgba(113, 113, 113, 0.31);
  }
}
.list-of-menu-items a {
  text-decoration: none;
  font-weight: 600;
  color: #0b132b;
  font-size: 0.9em;
  border: none;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  @include respond-to("medium") {
    background: none;
    width: 100%;
    color: #0b132b;
  }
}

.list-of-menu-items a:hover {
  background-color: #f0eeee;
  @include respond-to("medium") {
    background: none;
  }
}
.list-of-menu-items a:active {
  background-color: #ddd;
  @include respond-to("medium") {
    background: none;
  }
}
.navbar-not-visible {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-inline: 1.5rem;
  padding-block: 1rem;
  background-color: white;
  z-index: 10;
  position: sticky;
  transition: top 0.3s ease-in-out;
  top: -70px;
  box-shadow: -1px -5px 20px 2px #0000004f;
  color: #0b132b;
}

.visible {
  display: none;
  @include respond-to("medium") {
    visibility: visible;
    display: flex;
  }
  @include respond-to("small") {
    visibility: visible;
    display: flex;
  }
  & > svg {
    cursor: pointer;
  }
}
.hideOnMobile {
  visibility: visible;
  @include respond-to("medium") {
    visibility: hidden;
  }
  @include respond-to("small") {
    visibility: hidden;
  }
}
